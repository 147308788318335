import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';

import Template from './../layouts/Template';
import styled from 'styled-components';

const Hero = styled.section`
	.hero-title {
		${tw`relative text-white text-center font-round z-20`};
		padding-top: 10rem;
		h1 {
			${tw`font-round text-112 sm:text-167 text-white`};
		}
	}
	.blackout {
		${tw`w-full font-blackout text-128 sm:text-200 block text-center`};
		margin-top: -2rem;
		@media (min-width: 576px) {
			margin-top: -4rem;
		}
	}
`;

const Blob = styled.div`
	${tw`z-10`};
	overflow: hidden;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		flex: 0 0 auto;
		height: 36em;
		width: auto;
		@media (min-width: 50em) {
			height: 40em;
			margin-left: -14.354em;
		}
	}
`;

const StyledSection = styled.section`
	${tw`mt-15rem`};

	h2 {
		${tw`mb-1`};
	}

	.sophia {
		&__headshot {
			${tw`w-full md:w-1/3 mb-8 md:mb-0`};
			img {
				${tw`rounded-full mx-auto`};
				width: 278px;
				height: 278px;
				object-fit: cover;
			}
		}
		&__intro {
			${tw`w-full md:w-2/3`};
		}
	}
	ul {
		${tw`text-18 sm:text-24`};
	}
`;
const CardSection = styled.section`
	${tw`my-24 md:my-12rem`};
	.row {
		${tw`justify-between`};
	}
`;
const Card = styled.div`
	${tw`w-full mb-16`};
	@media (min-width: 768px) {
		width: 30%;
	}

	&:hover {
		.button {
			${tw`bg-secondary border-secondary text-white`};
		}
	}
`;

const PortfolioSection = styled.section`
	${tw`mt-24 md:mt-12rem text-center pb-20rem`}
	.title-big {
		top: -0.55em;
	}
	h2 {
		font-size: inherit;
	}
	.row {
		${tw`justify-between`};
	}
	.button {
		${tw`mb-16`};
	}
`;

const PortfolioItem = styled.div`
	${tw`w-full mb-12`};
	img {
		${tw`mb-3`};
	}
	&:hover {
	}

	@media (min-width: 768px) {
		width: 30%;
	}
`;
class Index extends Component {
	render() {
		return (
			<Template
				isIndex={true}
				title={this.props.data.site.siteMetadata.title}
				desc="Sophia Zey: Front End Web Developer and Web Designer"
			>
				<Hero>
					<Blob>
						<svg width="4098px" height="634px" viewBox="0 0 4098 634" version="1.1" xmlns="http://www.w3.org/2000/svg">
							<desc>Created with Sketch.</desc>
							<defs>
								<linearGradient x1="74.2685859%" y1="112.742039%" x2="1.90501069%" y2="2.89186588%" id="linearGradient-1">
									<stop stopColor="#B200BF" offset="0%" />
									<stop stopColor="#6D0095" offset="100%" />
								</linearGradient>
							</defs>
							<g id="💜" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<g id="background-copy" fill="url(#linearGradient-1)" fillRule="nonzero">
									<path
										d="M1068,515 C1188.8406,557.198303 1258,594 1329,594 C1400,594 1502.26771,506.725894 1582,515 C1688,526 1824.02509,606.202036 1880,621 C1967,644 2018,637 2121,594 C2268,530 2340,611 2418,594 C2496,577 2596.89097,409.624288 2645,366 C2693.10903,322.375712 2720,306 2769,285 C2946,209 3068,330 3277,330 C3451.52654,330 3543.4314,142.859523 3745,97 C3934,54 4025,105 4098,0 C3094,3.59897297e-15 1728,3.59897297e-15 0,0 C156.41913,184.660626 341.085797,325.660626 554,423 C696,487.919066 947.159404,472.801697 1068,515 Z"
										id="Path-Copy"
									/>
								</g>
							</g>
						</svg>
					</Blob>

					<div className="hero-title">
						<h1 className="neon">
							Sophia <span className="blackout">Zey</span>
						</h1>
					</div>
				</Hero>

				<StyledSection>
					<div className="sophia container">
						<div className="row ">
							<div className="sophia__headshot centered">
								<img src="/sophiazey.jpg" width="100%" alt="sophia zey headshot" />
							</div>
							<div className="sophia__intro centered">
								<div>
									<h2>Front-end Web Developer/ Designer</h2>
									<ul>
										<li>
											Former opera singer <span className="d-medium"> turned developer and designer</span>
										</li>
										<li>
											Lifelong student <span className="d-medium"> always learning new techniques</span>
										</li>
										<li>Creative thinker</li>
									</ul>
									<Link to="/about" className="button">
										About Me
									</Link>
								</div>
							</div>
						</div>
					</div>
				</StyledSection>
				<CardSection>
					<div className="container">
						<div className="row">
							<Card className="box">
								<Link to="/now">
									<div className="title-big">NOW</div>
									<div className="subtitle">LAST UPDATED</div>
									<p>February 13th, 2019</p>
									<div className="button">What's Up</div>
								</Link>
							</Card>
							<Card className="box">
								<Link to="/blog">
									<div className="title-big">BLOG</div>
									<div className="subtitle">NEWEST POST</div>
									<p>My Old Site</p>
									<div className="button">Read Me</div>
								</Link>
							</Card>
							<Card className="box">
								<Link to="/contact">
									<div className="title-big">CONTACT</div>
									<div className="subtitle">Details</div>
									<p>Get ahold of me</p>
									<div className="button">Say Hello</div>
								</Link>
							</Card>
						</div>
					</div>
				</CardSection>
				<PortfolioSection>
					<div className="container">
						<div className="box">
							<div className="title-big">
								<h2>Portfolio</h2>
								<div className="subtitle text-black">Selective Works</div>
							</div>
							<div className="row">
								<PortfolioItem>
									<Link to="/tower-paints/">
										<img src="/tower-paints.jpg" width="100%" alt="tower paints" />
										<h3>Concrete Driveway</h3>
										<div className="subtitle">UX/ui + bootstrap4 + html/css3</div>
									</Link>
								</PortfolioItem>
								<PortfolioItem>
									<Link to="/belong-book/">
										<img src="/belong-book.jpg" width="100%" alt="belong book" />
										<h3>Belong Book</h3>
										<div className="subtitle">Bootstrap4 + html/css3</div>
									</Link>
								</PortfolioItem>
								<PortfolioItem>
									<Link to="/tyler-zey/">
										<img src="/tyler-zey.jpg" width="100%" alt="tyler zey" />
										<h3>TYLER ZEY</h3>
										<div className="subtitle">ux/ui + react, tailwind.js, scss</div>
									</Link>
								</PortfolioItem>
								<div className="center-button">
									<Link to="/portfolio" className="button">
										MORE INFO
									</Link>
								</div>
							</div>
						</div>
					</div>
				</PortfolioSection>
			</Template>
		);
	}
}

export default Index;

export const pageQuery = graphql`
	query IndexQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
